declare module '@mui/material/styles' {
  interface TypographyVariants {
    chip: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    chip?: React.CSSProperties;
  }
}

import { defaultTheme, defaultDarkTheme } from 'react-admin';
import { deepmerge } from '@mui/utils';

export const myTheme = deepmerge(defaultTheme, {
    components: {
        ...defaultTheme.components,
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    '&.custom-multiline .MuiInputBase-inputMultiline': {
                        minHeight: '50px', // Deze stijl zal alleen toegepast worden op textareas met de 'custom-min-height' klasse
                        fontSize: '1rem',
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none', // remove app bar shadow
                    textTransform: 'capitalize',
                },
            }
        },
        RaSidebar: {
            styleOverrides: {
                docked: {
                    display: 'none'
                }
            }
        },
        // override default menu item state
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    color: '#172121', // Donkerblauwe kleur voor alle buttons
                }
            }
        },
        RaDatagrid: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(247, 247, 242, 0.2)',
                    borderRadius: '12px 12px 0 0',
                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                    overflow: 'hidden',
                    '& .RaDatagrid-headerCell': {
                        fontWeight: 600,
                        padding: '12px 16px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                        fontSize: '0.875rem',
                        backgroundColor: 'rgba(247, 247, 242, 0.2)'
                    },
                    '& .MuiTableCell-root *': {
                        fontSize: '0.875rem',
                    },
                    '& .RaDatagrid-row': {
                        cursor: 'pointer',
                        backgroundColor: 'rgba(247, 247, 242, 0.2)',
                        transition: 'all 0.2s ease',
                        '& .MuiTableCell-root': {
                            fontSize: '1rem',
                        },
                        '&:hover': {
                            backgroundColor: '#f8fafc',
                        },
                    },
                    '& .MuiTableCell-root': {
                        padding: '12px 16px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    },
                    '& .MuiToolbar-root.MuiTablePagination-toolbar': {
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '16px',
                        backgroundColor: '#fff',
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                        '& .MuiTablePagination-spacer': {
                            display: 'none',
                        },
                    },
                    '& .MuiTablePagination-selectLabel': {
                        margin: 0,
                    },
                    '& .MuiTablePagination-select': {
                        marginLeft: '8px',
                        marginRight: '32px',
                        paddingRight: '24px',
                        border: 'none',
                        '&:focus': {
                            backgroundColor: 'transparent'
                        }
                    },
                    '& .MuiTablePagination-displayedRows': {
                        margin: 0,
                    },
                    '& .MuiTablePagination-actions': {
                        display: 'flex',
                        gap: '8px',
                        '& .MuiPagination-ul': {
                            display: 'flex',
                            gap: '8px',
                        },
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {        
                    // Standaard stijlen voor MuiMenuItem
                    '&:hover': {
                        color: 'rgba(252, 128, 119, 0.8)', // Roze kleur met lichte opacity voor hover
                        backgroundColor: 'transparent !important', // Geen achtergrondkleur bij hover                            
                    },
                    // Specifieke stijlen voor geselecteerde menu-items
                    '&.RaMenuItemLink-active': {
                        color: '#FC8077 !important', // Roze kleur voor actieve items
                        backgroundColor: 'rgba(252, 128, 119, 0.10)',
                        fontWeight: 'bold',
                        '&:hover': {
                            color: 'rgba(252, 128, 119, 1) !important', // Volle roze kleur voor hover op actieve items
                        },
                    },
                },
            },

        },
        MuiDrawer: {
            styleOverrides: {
                docked: {
                    borderRight: '1px solid rgba(0, 0, 0, 0.12)', // Voeg een rechterrand toe
                    marginRight: '10px',
                },
            },
        },        
        // remove appbar shadow
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none', 
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)', 
                },
            },
        },
        // remove paper shadow and table borders
        MuiPaper: {
            styleOverrides: {
                root: {
                    border: 'none', // Verwijder de linker rand
                    boxShadow: 'none', // remove app ba
                    backgroundColor: '#ffffff',
                },
            },
        },
        // column headers in bold
        MuiTableCell: {
            styleOverrides: {
                head: { // 
                    fontWeight: 'bold',
                    borderBottom: '2px solid rgba(0, 0, 0, 0.22)', // Voeg een 2px dikke onderste rand toe
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 'auto',
                    borderRadius: 12,
                },
                flexContainer: {
                    padding: 4,
                }
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    maxHeight: 40,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minWidth: 0,
                    borderRadius: 4,
                    minHeight: 'auto',
                    textTransform: 'none',
                    '&.Mui-selected': {
                        fontWeight: 'bold',
                    },
                    '& .MuiTab-iconWrapper': {
                        marginRight: 8,
                    },
                },
            },
        },
    },
    sidebar: {
        width: 210,
        closedWidth: 55,
    },
    palette: {
        primary: {
            main: '#368F8B',
        },
        secondary: {
            main: '#ffffff',
            contrastText: '#141c1c',
        },
        text: {
            primary: '#172121',
            secondary:'#A1A9B8'
        },
        background: {
            default: '#FFFFFF',
            paper: '#F7F7F2',
        },
        info: {
            main: '#FC8077',
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 12,
    },
    typography: {
        fontFamily: ['Figtree', '-apple-system', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
        fontSize: 12,
        h1: {
            fontSize: '2.5rem',     // 35px
            fontWeight: 700,
            lineHeight: 1.2,
        },
        h2: {
            fontSize: '2rem',       // 28px
            fontWeight: 600,
            lineHeight: 1.3,
        },
        h3: {
            fontSize: '1.5rem',     // 21px
            fontWeight: 600,
            lineHeight: 1.4,
        },
        h4: {
            fontSize: '1.25rem',    // 17.5px
            fontWeight: 600,
            lineHeight: 1.4,
        },
        body1: {
            fontSize: '1rem',       // 14px
            lineHeight: 1.5,
        },
        body2: {
            fontSize: '0.875rem',   // 12.25px
            lineHeight: 1.5,
        },
        button: {
            fontSize: '0.875rem',   // 12.25px
            fontWeight: 500,
            textTransform: 'none',
        },
        caption: {
            fontSize: '0.75rem',    // 10.5px
            lineHeight: 1.5,
        }
    },
    // Custom sectie voor StructuredJsonField
    customComponents: {
        StructuredJsonField: {
            roles: {
                assistant: {
                    bgColor: 'white',
                    textColor: 'default',
                    avatarColor: '#368F8B',
                },
                user: {
                    bgColor: '#f7f7f7',
                    textColor: 'default',
                    avatarColor: '#FC8077',
                },
                system: {
                    bgColor: 'white',
                    textColor: '#777777',
                    avatarColor: '#777777',
                },
                tool: {
                    bgColor: '#fff8f7',
                    textColor: '#777777',
                    avatarColor: '#777777',
                },
                default: {
                    bgColor: 'white',
                    textColor: 'default',
                    avatarColor: '#999999',
                }
            }
        }
    }
});

// Dark theme
export const myDarkTheme = deepmerge(defaultDarkTheme, {
    // ... bestaande dark theme configuratie ...
    components: {
        MuiDrawer: {
            styleOverrides: {
                docked: {
                    borderRight: '1px solid rgba(0, 0, 0, 0.12)', // Voeg een rechterrand toe
                    marginRight: '10px',
                },
            },
        },  
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none', // remove app bar shadow
                    textTransform: 'capitalize',
                },
            }
        },
        RaSidebar: {
            styleOverrides: {
                docked: {
                    display: 'none'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: 'auto',
                    textTransform: 'none',
                    '&.Mui-selected': {
                        color: '#FC8077',
                        fontWeight: 'bold',
                    },
                    '& .MuiTab-iconWrapper': {
                        marginRight: 8,
                    },
                },
            },
        },
    },
    sidebar: {
        width: 210, 
        closedWidth: 55, 
    },
    
    customComponents: {
        StructuredJsonField: {
            roles: {
                assistant: {
                    bgColor: '#1e1e1e',
                    textColor: '#ffffff',
                    avatarColor: '#368F8B',
                },
                user: {
                    bgColor: '#2d2d2d',
                    textColor: '#ffffff',
                    avatarColor: '#FC8077',
                },
                system: {
                    bgColor: '#1e1e1e',
                    textColor: '#a0a0a0',
                    avatarColor: '#666666',
                },
                tool: {
                    bgColor: '#252525',
                    textColor: '#a0a0a0',
                    avatarColor: '#666666',
                },
                default: {
                    bgColor: '#1e1e1e',
                    textColor: '#ffffff',
                    avatarColor: '#666666',
                }
            }
        }
    }
});