import { Card, CardContent, Typography, Box, useTheme } from "@mui/material";
import { Icon } from "@mui/material";
import { useTranslate } from 'react-admin';

interface TotalCallsCardProps {
  total: number;
  title: string;
  icon: React.ReactNode;
  unit?: string;
  suffix?: string;
}

export const TotalCallsCard = ({ 
  total, 
  title, 
  icon, 
  unit = '', 
  suffix = ''
}: TotalCallsCardProps) => {
  const translate = useTranslate();
  const theme = useTheme();

  return (
    <Card 
      sx={{
        borderRadius: 3,
        backgroundColor: `${theme.palette.primary.main}12`,
        border: `1px solid ${theme.palette.primary.main}40`,
        boxShadow: `0 2px 4px ${theme.palette.primary.main}12`
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center" gap={1} mb={1}>
          <Icon>{icon}</Icon>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        </Box>
        <Typography variant="h4" component="div" sx={{ display: 'flex', alignItems: 'baseline' }}>
          {total}
          {(unit || suffix) && (
            <Typography component="span" variant="h6" sx={{ ml: 1, color: 'text.secondary' }}>
              {unit || suffix}
            </Typography>
          )}
        </Typography>
      </CardContent>
    </Card>
  );
}; 