import { Card, CardContent, Typography, Box, useTheme } from "@mui/material";
import { Icon } from "@mui/material";
import { useLocaleState } from 'react-admin';
import { useTranslate } from 'react-admin';

interface DurationCardProps {
  seconds: number;
  title: string;
  icon: React.ReactNode;
}

export const DurationCard = ({ 
  seconds, 
  title, 
  icon,
}: DurationCardProps) => {
  const theme = useTheme();
  const [locale] = useLocaleState();

  const formatDuration = (totalSeconds: number) => {
    const safeLocale = locale?.replace('_', '-') || 'nl-NL';
    
    try {
      // Create separate formatter for each unit to ensure correct unit formatting
      const dayFormatter = new Intl.NumberFormat(safeLocale, { 
        style: 'unit',
        unit: 'day',
        unitDisplay: 'long',
      });
      
      const hourFormatter = new Intl.NumberFormat(safeLocale, { 
        style: 'unit',
        unit: 'hour',
        unitDisplay: 'long',
      });
      
      const minuteFormatter = new Intl.NumberFormat(safeLocale, { 
        style: 'unit',
        unit: 'minute',
        unitDisplay: 'long',
      });
      
      const secondFormatter = new Intl.NumberFormat(safeLocale, { 
        style: 'unit',
        unit: 'second',
        unitDisplay: 'long',
      });

      // Convert to the most appropriate unit
      if (totalSeconds >= 86400) { // Days
        const days = Math.floor(totalSeconds / 86400);
        const hours = Math.floor((totalSeconds % 86400) / 3600);
        return `${dayFormatter.format(days)} ${hourFormatter.format(hours)}`;
      } else if (totalSeconds >= 3600) { // Hours
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        return `${hourFormatter.format(hours)} ${minuteFormatter.format(minutes)}`;
      } else if (totalSeconds >= 60) { // Minutes
        const minutes = Math.floor(totalSeconds / 60);
        return minuteFormatter.format(minutes);
      } else { // Seconds
        return secondFormatter.format(totalSeconds);
      }
    } catch (error) {
      // Fallback formatting if Intl formatting fails
      if (totalSeconds >= 86400) {
        const days = Math.floor(totalSeconds / 86400);
        const hours = Math.floor((totalSeconds % 86400) / 3600);
        return `${days} days ${hours} hours`;
      } else if (totalSeconds >= 3600) {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        return `${hours} uur ${minutes} minutes`;
      } else if (totalSeconds >= 60) {
        const minutes = Math.floor(totalSeconds / 60);
        return `${minutes} minutes`;
      }
      return `${totalSeconds} seconds`;
    }
  };

  return (
    <Card 
      sx={{
        borderRadius: 3,
        backgroundColor: `${theme.palette.primary.main}12`,
        border: `1px solid ${theme.palette.primary.main}40`,
        boxShadow: `0 2px 4px ${theme.palette.primary.main}12`
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center" gap={1} mb={1}>
          <Icon>{icon}</Icon>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        </Box>
        <Typography variant="h4" component="div">
          {formatDuration(seconds)}
        </Typography>
      </CardContent>
    </Card>
  );
}; 