import * as React from 'react';
import { Box, Typography, Paper, Button, Chip, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useGetIdentity, useCreate, useUpdate, useDelete, useGetList } from 'react-admin';
import { IconPlus, IconLock, IconPencil, IconTrash } from '@tabler/icons-react';
import { ConversationNoteCreateModal } from './ConversationNoteCreateModal';
import { ConversationNoteEditModal } from './ConversationNoteEditModal';
import { useUsersWithPollie } from '../hooks/useUsersWithPollie';

interface Note {
    id: string | number;
    title: string;
    status: 'opened' | 'closed' | 'none';
    isPrivate: boolean;
    createdBy: string;
    createdAt: string;
    assignedTo: number | null;
    assignedToName: string;
}

interface ConversationNotesProps {
    record?: any;
}

const ConversationNotes = ({ record }: ConversationNotesProps) => {
    const theme = useTheme();
    const { identity } = useGetIdentity();
    const [createModalOpen, setCreateModalOpen] = React.useState(false);
    const [editModalOpen, setEditModalOpen] = React.useState(false);
    const [selectedNote, setSelectedNote] = React.useState(null);
    const { data: notes } = useGetList('conversation-notes', {
        filter: { conversation_id: record?.id },
        sort: { field: 'created_at', order: 'DESC' }
    });

    const { users, isLoading: usersLoading } = useUsersWithPollie({
        customerId: record?.customer_id
    });


    const [create] = useCreate();
    const [update] = useUpdate();
    const [deleteOne] = useDelete();

    const handleAddNote = (noteData: {
        title: string;
        status: 'opened' | 'closed' | 'none';
        isPrivate: boolean;
        assignedTo?: string;
    }) => {

        create('conversation-notes', {
            data: {
                content: noteData.title,
                status: noteData.status,
                is_private: noteData.isPrivate,
                assigned_to: noteData.assignedTo ? parseInt(noteData.assignedTo, 10) : null,
                conversation_id: record.id,
                created_by: identity?.id,
            }
        });
        setCreateModalOpen(false);
    };

    const handleEditNote = (note: any) => {
        update('conversation-notes', {
            id: note.id,
            data: {
                content: note.title,
                status: note.status,
                is_private: note.isPrivate,
                assigned_to: note.assignedTo ? parseInt(note.assignedTo, 10) : null,
            }
        });
        setEditModalOpen(false);
    };

    const handleDeleteNote = (noteId: string) => {
        deleteOne('conversation-notes', { id: noteId });
    };

    // Transform notes data to match our component's format
    const transformedNotes = React.useMemo(() => {
        console.log('Transforming notes:', notes);
        return notes?.map((note: any) => {
            console.log('Processing note:', note);
            const assignedUser = users?.find((user: any) => user.id === note.assigned_to);
            return {
                id: note.id,
                title: note.content,
                status: note.status || 'none',
                isPrivate: note.is_private || false,
                createdBy: note.created_by,
                createdAt: note.created_at,
                assignedTo: note.assigned_to,
                assignedToName: assignedUser ? `${assignedUser.first_name} ${assignedUser.last_name}` : '',
            };
        }) || [];
    }, [notes, users]);

    // Transform users data for the modals
    const transformedUsers = React.useMemo(() => {
        return users?.map((user: any) => ({
            id: user.id,
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
        })) || [];
    }, [users]);

    return (
        <Paper sx={{
            p: theme.spacing(2),
            backgroundColor: theme.palette.background.default,
            boxShadow: theme.shadows[0],
            height: '79vh',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Notes</Typography>
                <Button
                    startIcon={<IconPlus size={18} />}
                    variant="outlined"
                    onClick={() => setCreateModalOpen(true)}
                >
                    Add a note
                </Button>
            </Box>

            <Box sx={{ 
                flexGrow: 1,
                overflowY: 'auto',
                gap: 2,
                display: 'flex',
                flexDirection: 'column',
            }}>
                {transformedNotes?.length > 0 ? (
                    transformedNotes.map((note: Note) => (
                        <Paper
                            key={note.id}
                            sx={{
                                p: 2,
                                backgroundColor: note.status === 'opened' ? 
                                    'rgba(235, 241, 255, 0.5)' : 
                                    'rgba(255, 235, 235, 0.5)',
                                borderRadius: 1
                            }}
                        >
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                mb: 2
                            }}>
                                <Typography variant="subtitle1">{note.title}</Typography>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Chip
                                        label={note.status}
                                        size="small"
                                        color={note.status === 'opened' ? 'success' : 'error'}
                                        sx={{ textTransform: 'capitalize' }}
                                    />
                                    {note.isPrivate && (
                                        <IconButton size="small">
                                            <IconLock size={18} />
                                        </IconButton>
                                    )}
                                    <IconButton size="small" onClick={() => {
                                        setSelectedNote(note);
                                        setEditModalOpen(true);
                                    }}>
                                        <IconPencil size={18} />
                                    </IconButton>
                                    <IconButton size="small" onClick={() => handleDeleteNote(note.id)}>
                                        <IconTrash size={18} />
                                    </IconButton>
                                </Box>
                            </Box>

                            <Box sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                                <Typography component="span">
                                    Created by {note.createdBy} on {new Date(note.createdAt).toLocaleDateString()}
                                </Typography>
                                {note.assignedToName && (
                                    <Typography component="span" sx={{ ml: 1 }}>
                                        • Assigned to {note.assignedToName}
                                    </Typography>
                                )}
                            </Box>
                        </Paper>
                    ))
                ) : (
                    <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                        No notes available
                    </Typography>
                )}
            </Box>

            <ConversationNoteCreateModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleAddNote}
                users={users}
            />

            {selectedNote && (
                <ConversationNoteEditModal
                    open={editModalOpen}
                    onClose={() => {
                        setEditModalOpen(false);
                        setSelectedNote(null);
                    }}
                    onSubmit={handleEditNote}
                    note={selectedNote}
                    users={transformedUsers}
                />
            )}
        </Paper>
    );
};

export default ConversationNotes; 