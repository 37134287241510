import * as React from 'react';
import { Box, Typography, Avatar, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PollieIcon from '../layout/PollieIcon';
import { IconUser, IconRefresh, IconChevronDown } from '@tabler/icons-react';
import { useTranslate } from 'react-admin';

interface Message {
    role: 'system' | 'assistant' | 'tool' | 'user';
    content: string | null;
    tool_calls?: {
        id: string;
        function: {
            name: string;
            arguments: string;
        };
        type: string;
    }[];
    tool_call_id?: string;
    refusal?: string | null;
}

interface ConversationTranscriptProps {
    messages: Message[];
}

const ConversationTranscript = ({ messages }: ConversationTranscriptProps) => {
    const theme = useTheme();
    const translate = useTranslate();
    const parsedMessages = React.useMemo(() => {
        if (!messages) return [];
        if (Array.isArray(messages)) return messages;
        try {
            return JSON.parse(messages);
        } catch (error) {
            console.error('Failed to parse messages:', error);
            return [];
        }
    }, [messages]);

    if (!parsedMessages || parsedMessages.length === 0) return null;

    return (
        <Paper sx={{
            p: theme.spacing(2),
            backgroundColor: theme.palette.background.default,
            boxShadow: theme.shadows[0],
            height: '79vh',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography
                variant="h5"
                sx={{
                    fontWeight: 'bold',
                    mb: theme.spacing(2)
                }}
            >
                {translate('resources.conversations.fields.transcript')}
            </Typography>

            <Box sx={{ 
                flexGrow: 1,
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    borderRadius: '4px',
                }
            }}>
                {parsedMessages.map((message: Message, index: number) => (
                    <Box
                        key={index} 
                        sx={{ 
                            display: 'flex', 
                            justifyContent: message.role === 'assistant' ? 'flex-end' : 'flex-start',
                            mb: 2,
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{ 
                                width: '70%',
                                display: 'flex',
                                flexDirection: message.role === 'assistant' ? 'row-reverse' : 'row',
                                alignItems: 'flex-start',
                                gap: 2
                            }}
                        >
                            {message.role === 'assistant' ? (
                                message.tool_calls ? (
                                    <IconRefresh size={32} color={theme.palette.primary.main} />
                                ) : (
                                    <PollieIcon sx={{ width: 32, height: 32 }}/>
                                )
                            ) : (
                                <Avatar 
                                    sx={{ 
                                        bgcolor: 'transparent',
                                        border: `1px solid ${theme.palette.info.main}`,
                                        width: 32,
                                        height: 32
                                    }}
                                >
                                    <IconUser size="1rem" color={theme.palette.info.main} />
                                </Avatar>
                            )}
                            <Box sx={{ width: '100%' }}>
                                <Paper 
                                    sx={{ 
                                        p: 2,
                                        bgcolor: message.tool_calls 
                                            ? 'rgba(102, 157, 153, 0.2)'
                                            : message.role === 'assistant' 
                                                ? 'primary.light' 
                                                : 'grey.100',
                                        mb: 1,
                                        width: '100%'
                                    }}
                                >
                                    <Typography 
                                        variant="body2" 
                                        color={message.role === 'assistant' && !message.tool_calls ? 'white' : 'inherit'}
                                        sx={{
                                            overflowWrap: 'break-word',
                                            wordBreak: 'break-all'
                                        }}
                                    >
                                        {message.content}
                                    </Typography>
                                    {message.tool_calls && (
                                        <Box sx={{ mt: 1, width: '100%' }}>
                                            <details>
                                                <summary 
                                                    style={{ 
                                                        cursor: 'pointer',
                                                        listStyle: 'none',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between'
                                                    }}
                                                >
                                                    <Typography 
                                                        variant="body2" 
                                                        sx={{ 
                                                            mb: 0.5,
                                                            fontSize: '0.875rem'
                                                        }}
                                                    >
                                                        {message.tool_calls[0]?.function?.name || 'Tool Call'}
                                                    </Typography>
                                                    <IconChevronDown 
                                                        size={18} 
                                                        style={{ 
                                                            transition: 'transform 0.2s',
                                                            transform: 'rotate(0deg)'
                                                        }}
                                                    />
                                                </summary>
                                                <Box
                                                    sx={{ 
                                                        mt: 1, 
                                                        p: 1, 
                                                        bgcolor: 'rgba(255, 255, 255, 0.5)',
                                                        maxHeight: '200px',
                                                        overflowY: 'auto',
                                                        overflowX: 'hidden',
                                                        width: '100%',
                                                        '&::-webkit-scrollbar': {
                                                            width: '8px',
                                                        },
                                                        '&::-webkit-scrollbar-thumb': {
                                                            backgroundColor: 'rgba(0,0,0,0.2)',
                                                            borderRadius: '4px',
                                                        }
                                                    }}
                                                >
                                                    <Typography 
                                                        variant="body2" 
                                                        sx={{ 
                                                            whiteSpace: 'pre-wrap',
                                                            wordBreak: 'break-word',
                                                            width: '100%',
                                                            fontSize: '0.875rem'
                                                        }}
                                                    >
                                                        {JSON.stringify(message.tool_calls, null, 2)}
                                                    </Typography>
                                                </Box>
                                            </details>
                                        </Box>
                                    )}
                                </Paper>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Paper>
    );
};

export default ConversationTranscript;
