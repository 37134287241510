import { useState } from 'react';
import { TextInputProps, useTranslate } from 'react-admin';
import { TextInput } from 'react-admin';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import zxcvbn from 'zxcvbn';

type PasswordMode = 'create' | 'reset' | 'change' | 'confirmation';

interface PasswordInputProps extends Omit<TextInputProps, 'type'> {
  mode: PasswordMode;
  isRequired?: boolean;
  label?: string;
  source: string;
}

const validatePassword = [
  (value: string) => {
    if (!value) return 'ra.validation.required';
    if (value.length < 8) return 'ra.validation.minLength';
    if (value.length > 64) return 'ra.validation.maxLength';
    if (!/[A-Z]/.test(value)) return 'ra.validation.password.uppercase';
    if (!/[a-z]/.test(value)) return 'ra.validation.password.lowercase';
    if (!/[0-9]/.test(value)) return 'ra.validation.password.number';
    if (!/[!@#$%^&*]/.test(value)) return 'ra.validation.password.special';

    const result = zxcvbn(value);
    if (result.score < 3) return 'ra.validation.password.weak';

    return undefined;
  }
];

const getValidation = (mode: PasswordMode) => {
  if (mode === 'confirmation') {
    return [
      (value: string, values: any) => 
        value !== values.password
          ? 'ra.validation.password.mismatch'
          : undefined,
    ];
  }

  const baseValidation = validatePassword;

  if (mode === 'change') {
    return [
      ...baseValidation,
      (value: string, values: any) => 
        value === values.oldPassword
          ? 'ra.validation.password.same'
          : undefined,
    ];
  }

  return baseValidation;
};

const 
PasswordInput = ({
  mode,
  isRequired = true,
  helperText,
  label,
  source,
  ...rest
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const translate = useTranslate();

  const validate = getValidation(mode);

  const getHelperText = () => {
    if (helperText) return helperText;

    switch (mode) {
      case 'create':
        return translate('ra.message.password.create');
      case 'reset':
        return translate('ra.message.password.reset');
      case 'change':
        return translate('ra.message.password.change');
      case 'confirmation':
        return translate('ra.message.password.confirmation');
      default:
        return '';
    }
  };

  return (
    <TextInput
      source={source}
      label={label}
      validate={validate}
      type={showPassword ? 'text' : 'password'}
      required={isRequired}
      sx={{ width: '100%' }}
    />
  );
};

export default PasswordInput;
