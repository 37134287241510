import React from 'react';
import { styled } from '@mui/material/styles';
import { FilterButton } from 'react-admin';

export const CustomFilterButton = styled(FilterButton)(({ theme }) => ({
    '& .MuiButton-root': {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
}));
