import { useGetIdentity, Title, useTranslate, useGetList, Form, ReferenceArrayInput, AutocompleteArrayInput, usePermissions } from "react-admin";
import { Card, CardContent, CardHeader, Typography, Grid, CircularProgress, Box, Button } from "@mui/material";
import { useEffect, useState, useCallback } from 'react';
import ConversationStatsChart from './components/ConversationStatsChart';
import { dataProvider } from './dataProvider';
import { TotalCallsCard } from './components/TotalCallsCard';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FilterListIcon from '@mui/icons-material/FilterList';
import { DurationCard } from "./components/DurationCard";



interface StatsData {
  call_count: number;
  call_duration: number;
  transferred_count: number;
  transfer_duration: number;

}

export const Dashboard = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const [filters, setFilters] = useState<{ 
    assistant_ids?: number[], 
    customer_ids?: number[] 
  }>({});
  const [dailyStats, setDailyStats] = useState<StatsData>();
  const [totalStats, setTotalStats] = useState<StatsData>();
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const fetchStats = useCallback(() => {
    if (permissions === 'admin') {
      setLoading(true);
      
      dataProvider.custom('api/charts/conversation-stats', {
          method: 'post',
          postdata: {
              assistant_ids: filters.assistant_ids,
              customer_ids: filters.customer_ids
          }
      })
      .then((response: { data: { daily_stats: any, total_stats: any } }) => {
          setDailyStats(response.data.daily_stats);
          setTotalStats(response.data.total_stats);
          setLoading(false);
      })
      .catch((error: Error) => {
          console.error('Error fetching stats:', error);
          setLoading(false);
      });
    }
  }, [filters, permissions]);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  const handleFilterChange = (newFilters: { assistant_ids?: number[], customer_ids?: number[] }) => {
    setFilters(prev => ({ ...prev, ...newFilters }));
  };
  const filterToQuery = (searchText: any) => ({ name: `%${searchText}%` });

  return (
    <>
      <Title title="Dashboard" />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Pollie Dashboard" />
            <CardContent>
              {identityLoading ? (
                <CircularProgress />
              ) : identity ? (
                <Typography sx={{ mb: 1 }}>
                  {translate("resources.dashboard.welcome_back")}, {identity.first_name}
                </Typography>
              ) : (
                <Typography sx={{ mb: 1 }}>No user data available.</Typography>
              )}
              
              {permissions === 'admin' && (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
                    <Button
                      startIcon={<FilterListIcon />}
                      onClick={() => setShowFilters(!showFilters)}
                      variant="outlined"
                    >
                      {showFilters ? translate("resources.generic.hide_filters") : translate("resources.generic.show_filters")}
                    </Button>
                  </Box>
                  
                  {showFilters && (
                    <Form>
                      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                        <ReferenceArrayInput source="assistant_id" reference="assistants">
                          <AutocompleteArrayInput 
                            optionText="name" 
                            sx={{ width: '300px' }} 
                            filterToQuery={filterToQuery} 
                            onChange={(data) => handleFilterChange({ assistant_ids: data })} 
                          />
                        </ReferenceArrayInput>
                        {['admin', 'partner'].includes(permissions) && 
                        <ReferenceArrayInput source="customer_id" reference="customers">
                          <AutocompleteArrayInput 
                            optionText="name" 
                            sx={{ width: '300px' }} 
                            filterToQuery={filterToQuery} 
                            onChange={(data) => handleFilterChange({ customer_ids: data })} 
                          />
                        </ReferenceArrayInput>
                        }
                      </Box>
                    </Form>
                  )}
                  
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                    <Box sx={{ p: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <DurationCard 
                            seconds={totalStats?.call_duration || 0} 
                            title={translate("resources.conversations.stats.total_call_time")}
                            icon={<LocalPhoneIcon />}
                          />                          
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TotalCallsCard 
                            total={Math.round(totalStats?.call_count || 0)} 
                            title={translate("resources.conversations.stats.total_calls")} 
                            icon={<LocalPhoneIcon />} 
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TotalCallsCard 
                            total={totalStats?.call_count && totalStats?.transferred_count 
                              ? Math.round((totalStats.transferred_count / totalStats.call_count) * 100)
                              : 0} 
                            title={translate("resources.conversations.stats.transfer_count_percentage")} 
                            icon={<PhoneForwardedIcon />}
                            suffix="%"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TotalCallsCard 
                            total={totalStats?.call_duration && totalStats?.transfer_duration 
                              ? Math.round((totalStats.transfer_duration / totalStats.call_duration) * 100)
                              : 0} 
                            title={translate("resources.conversations.stats.transfer_duration_percentage")} 
                            icon={<PhoneForwardedIcon />}
                            suffix="%"
                          />
                        </Grid>
                      </Grid>
                    </Box>                
                    <ConversationStatsChart data={dailyStats} onFilterChange={handleFilterChange} />
                    </>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};