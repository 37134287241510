import { useListContext } from 'react-admin';
import { Box, Button, Typography, useTheme, useMediaQuery } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const CustomPagination = () => {
    const theme = useTheme();
    const { page, setPage, total, perPage } = useListContext();
    const totalPages = Math.ceil(total / perPage);
    const isMobile = useMediaQuery('(max-width:600px)');

    const getPageNumbers = () => {
        const pages = [];
        const isMobile = useMediaQuery('(max-width:600px)');

        if (isMobile) {
            // Mobile: Show exactly 3 numbers - first, current, last
            pages.push(1);
            if (page !== 1 && page !== totalPages) {
                pages.push(page);
            }
            if (totalPages > 1) {
                pages.push(totalPages);
            }
            return pages;
        }

        // Desktop logic remains unchanged
        const delta = 2;
        pages.push(1);
        let start = Math.max(2, page - delta);
        let end = Math.min(totalPages - 1, page + delta);

        if (start > 2) pages.push('...');
        for (let i = start; i <= end; i++) {
            pages.push(i);
        }
        if (end < totalPages - 1) pages.push('...');
        if (totalPages > 1) pages.push(totalPages);

        return pages;
    };

    return (
        <Box sx={{ 
            backgroundColor: 'rgba(247, 247, 242, 0.2)',
            display: 'flex', 
            justifyContent: 'space-between',
            padding: theme.spacing(1),
            gap: theme.spacing(1),
            alignItems: 'center',
            color: theme.palette.common.white,
            maxWidth: '100%',
            margin: '0 auto',
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
            borderBottom: `1px solid ${theme.palette.divider}`,
            borderLeft: `1px solid ${theme.palette.divider}`,
            borderRight: `1px solid ${theme.palette.divider}`,
            width:'100%',
            position: 'relative',
            '& .MuiButton-root': {
                minWidth: theme.spacing(2),
                zIndex: 1
            }
        }}>
            <Box sx={{ 
                flex: 1,
                display: 'flex', 
                justifyContent: 'center',
                minWidth: 'fit-content'
            }}>
                <Button 
                    onClick={() => setPage(page - 1)} 
                    disabled={page <= 1}
                    sx={{ 
                        color: '#808080', 
                        textTransform: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <ChevronLeft /> Previous
                </Button>
            </Box>

            <Box sx={{ 
                flex: 2,
                display: 'flex', 
                gap: 1,
                alignItems: 'center', 
                justifyContent: 'center',
                margin: '0 auto',
                flexWrap: 'wrap',
            }}>
                {getPageNumbers().map((pageNum, index) => (
                    <Typography
                        key={index}
                        onClick={() => pageNum !== '...' ? setPage(Number(pageNum)) : undefined}
                        sx={{
                            cursor: pageNum !== '...' ? 'pointer' : 'default',
                            fontWeight: 'normal',
                            color: '#000000',
                            fontSize: '14px',
                            padding: theme.spacing(2),
                            minWidth: theme.spacing(4),
                            width: theme.spacing(2),
                            height: theme.spacing(2),
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            ...(page === pageNum && {
                                border: '1px solid #000000',
                                borderColor: theme.palette.divider,
                                borderRadius: '8px',
                            }),
                        }}
                    >
                        {pageNum}
                    </Typography>
                ))}
            </Box>

            <Box sx={{ 
                flex: 1,
                display: 'flex', 
                justifyContent: 'center',
                minWidth: 'fit-content'
            }}>
                <Button 
                    onClick={() => setPage(page + 1)} 
                    disabled={page >= totalPages}
                    sx={{ 
                        color: '#808080',
                        textTransform: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    Next <ChevronRight />
                </Button>
            </Box>
        </Box>
    );
};

export default CustomPagination;
