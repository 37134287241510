import React, { useState, useRef } from 'react';
import { IconButton, Box, Slider, Paper } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { useTheme } from '@mui/material/styles';
import { th } from 'date-fns/locale';


interface AudioPlayerProps {
    src: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef<HTMLAudioElement>(null);
    const theme = useTheme();
    console.log('src', src);
    const [volume, setVolume] = useState(1);
    const [isVolumeHovered, setIsVolumeHovered] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    React.useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume;
        }
    }, []);

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const handlePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleTimeUpdate = () => {
        if (audioRef.current && !isDragging) {
            setCurrentTime(audioRef.current.currentTime);
        }
    };

    const handleLoadedMetadata = () => {
        if (audioRef.current) {
            setDuration(audioRef.current.duration);
        }
    };

    const handleSliderChange = (_event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setCurrentTime(newValue);
            if (audioRef.current) {
                audioRef.current.currentTime = newValue;
            }
        }
    };

    const handleSliderMouseDown = () => {
        setIsDragging(true);
        if (audioRef.current && isPlaying) {
            audioRef.current.pause();
        }
    };

    const handleSliderMouseUp = () => {
        setIsDragging(false);
        if (audioRef.current && isPlaying) {
            audioRef.current.play();
        }
    };

    const handleVolumeChange = (_event: Event | React.ChangeEvent<unknown>, newValue: number | number[]) => {
        if (audioRef.current && typeof newValue === 'number') {
            const volumeValue = newValue / 100;
            audioRef.current.volume = volumeValue;
            setVolume(volumeValue);
        }
    };

    return (
        <Paper sx={{ 
            display: 'flex', 
            alignItems: 'center',
            width: '100%',
            padding: 1,
            backgroundColor: 'white',
            mb: 1,
            boxShadow: theme.shadows[0]
        }}>
            <audio
                ref={audioRef}
                src={src}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
                onEnded={() => setIsPlaying(false)}
            />
            <IconButton 
                onClick={handlePlayPause} 
                size="small"
                sx={{ 
                    color: theme.palette.primary.contrastText,
                    backgroundColor: theme.palette.info.main,  // Coral/salmon color //TODO: make this a variable
                    padding: '4px',
                    mr: theme.spacing(1),
                    '&:hover': {
                        backgroundColor: theme.palette.info.main,
                    }
                }}
            >
                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>

            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                gap: 1,
                color: 'text.secondary',
                fontSize: '0.875rem',
                minWidth: '70px',
                mr: theme.spacing(2)
            }}>
                {formatTime(currentTime)} / {formatTime(duration)}
            </Box>

            <Slider
                size="small"
                value={currentTime}
                max={duration}
                onChange={handleSliderChange}
                onMouseDown={handleSliderMouseDown}
                onMouseUp={handleSliderMouseUp}
                sx={{ 
                    flex: 1,
                    '& .MuiSlider-thumb': {
                        width: 12,
                        height: 12,
                        backgroundColor: '#FF7F7F',
                    },
                    '& .MuiSlider-track': {
                        backgroundColor: '#FF7F7F',
                        height: 4,
                    },
                    '& .MuiSlider-rail': {
                        backgroundColor: '#EAEAEA',
                        height: 4,
                    }
                }}
            />

            <Box 
                sx={{ position: 'relative' }}
                onMouseEnter={() => setIsVolumeHovered(true)}
                onMouseLeave={() => setIsVolumeHovered(false)}
            >
                <IconButton 
                    size="small"
                    onClick={() => {
                        if (audioRef.current) {
                            const newVolume = volume === 0 ? 1 : 0;
                            audioRef.current.volume = newVolume;
                            setVolume(newVolume);
                        }
                    }}
                    sx={{ 
                        color: 'text.secondary',
                        padding: '4px'
                    }}
                >
                    {volume === 0 ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </IconButton>
                {isVolumeHovered && (
                    <Paper sx={{
                        position: 'absolute',
                        bottom: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: 40,
                        height: 100,
                        padding: 1,
                        display: 'flex',
                        alignItems: 'center',
                        zIndex: 1,
                        marginBottom: 1
                    }}>
                        <Slider
                            orientation="vertical"
                            size="small"
                            value={volume * 100}
                            onChange={handleVolumeChange}
                            sx={{ 
                                '& .MuiSlider-thumb': {
                                    width: 12,
                                    height: 12,
                                    backgroundColor: '#FF7F7F',
                                },
                                '& .MuiSlider-track': {
                                    backgroundColor: '#FF7F7F',
                                },
                                '& .MuiSlider-rail': {
                                    backgroundColor: '#EAEAEA',
                                }
                            }}
                        />
                    </Paper>
                )}
            </Box>
        </Paper>
    );
};

export default AudioPlayer; 