import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControlLabel, Checkbox, RadioGroup, Radio, FormControl, FormLabel, Select, MenuItem, Box, IconButton } from '@mui/material';
import { IconX } from '@tabler/icons-react';

interface Note {
    id: string;
    title: string;
    status: 'opened' | 'closed' | 'none';
    isPrivate: boolean;
    assignedTo?: string;
}

interface ConversationNoteEditModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (note: Note) => void;
    note: Note;
    users?: Array<{ id: string; name: string; email: string }>;
}

export const ConversationNoteEditModal = ({ open, onClose, onSubmit, note, users = [] }: ConversationNoteEditModalProps) => {
    const [formData, setFormData] = React.useState<Note>(note);

    React.useEffect(() => {
        setFormData(note);
    }, [note]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(formData);
        onClose();
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Edit Note
                <IconButton onClick={onClose} size="small">
                    <IconX size={18} />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            label="Note description"
                            multiline
                            rows={4}
                            fullWidth
                            value={formData.title}
                            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.isPrivate}
                                    onChange={(e) => setFormData({ ...formData, isPrivate: e.target.checked })}
                                />
                            }
                            label="Private"
                        />

                        <FormControl component="fieldset">
                            <FormLabel component="legend">Status</FormLabel>
                            <RadioGroup
                                row
                                value={formData.status}
                                onChange={(e) => setFormData({ ...formData, status: e.target.value as 'opened' | 'closed' | 'none' })}
                            >
                                <FormControlLabel value="none" control={<Radio />} label="None" />
                                <FormControlLabel value="opened" control={<Radio />} label="Opened" />
                                <FormControlLabel value="closed" control={<Radio />} label="Closed" />
                            </RadioGroup>
                        </FormControl>

                        <FormControl fullWidth>
                            <FormLabel component="legend" sx={{ mb: 1 }}>Assigned to</FormLabel>
                            <Select
                                value={formData.assignedTo || ''}
                                onChange={(e) => setFormData({ ...formData, assignedTo: e.target.value })}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {users.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {user.name} ({user.email})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button onClick={onClose} variant="outlined">Cancel</Button>
                    <Button type="submit" variant="contained">Submit</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}; 