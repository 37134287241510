import { useRecordContext, useTranslate, FunctionField } from 'react-admin';
import { Box, Paper, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconInfoCircle, IconPhone, IconClock, IconCalendar, IconUser, IconFlag, IconTag } from '@tabler/icons-react';
import CustomChip from './react-admin/CustomChip';
import DurationField from '../DurationField';
import { LocalCreatedAt } from '../conversations';
import AudioPlayer from './AudioPlayer';
import TagsManager from '../TagsManager';
import { IconMessageDots } from '@tabler/icons-react';


const outcomeConfig = {
    'no_action': { 
        color: '#d32f2f',
        icon: IconInfoCircle,
        size: 16
    },
    'information_provided': { 
        color: '#1976d2',
        icon: IconInfoCircle,
        size: 16
    },
    'completed': { 
        color: '#2e7d32',
        icon: IconInfoCircle,
        size: 16
    },
    'caller_follow_up': { 
        color: '#ed6c02',
        icon: IconInfoCircle,
        size: 16
    },
    'transferred': { 
        color: '#757575',
        icon: IconInfoCircle,
        size: 16
    }
} as const;

type OutcomeType = keyof typeof outcomeConfig;

interface ConversationRecord {
    outcome: OutcomeType;
    meta?: string;
    summary?: string;
    phone_number?: string;
    from_name?: string;
    duration?: number;
    created_at?: string;
    messages?: string;
    audio_url?: string;
    topic?: string;
    tags?: string[];
}

export const ConversationOverview = ({ record }: { record: ConversationRecord }) => {
    const translate = useTranslate();
    const theme = useTheme();

    if (!record) return null;

    return (
        <Box sx={{ boxShadow: theme.shadows[0], backgroundColor: theme.palette.background.paper }} >
            {record.audio_url && <AudioPlayer src={record.audio_url} />}
            <Paper sx={{
                p: theme.spacing(2),
                backgroundColor: theme.palette.background.default,
                boxShadow: theme.shadows[0],
                height: '79vh',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Box sx={{ mb: theme.spacing(4) }}>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 'bold',
                            mb: theme.spacing(4)
                        }}
                    >
                        {translate('resources.conversations.fields.summary')}
                    </Typography>
                    <Paper 
                        sx={{ 
                            p: 2, 
                            bgcolor: theme.palette.grey[50],
                        }}
                    >
                        <Typography>
                            {record.summary || translate('resources.conversations.no_summary')}
                        </Typography>
                    </Paper>
                </Box>
                <Box height="100%">
                    <Typography variant="h5" gutterBottom fontWeight="bold" mb={theme.spacing(2)}>
                        {translate('resources.conversations.fields.details')}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(2) }}>
                                    <IconUser size="1rem" style={{ marginRight: 8 }} />
                                    <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                        {translate('resources.conversations.fields.name')}:
                                    </Typography>
                                </Box>
                                <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                    <Typography>{record.from_name || translate('resources.conversations.unknown')}</Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(2) }}>
                                    <IconPhone size="1rem" style={{ marginRight: 8 }} />
                                    <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                        {translate('resources.conversations.fields.phone_number')}:
                                    </Typography>
                                </Box>
                                <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                    <Typography>{record.phone_number || translate('resources.conversations.unknown')}</Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(2) }}>
                                    <IconCalendar size="1rem" style={{ marginRight: 8 }} />
                                    <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                        {translate('resources.conversations.fields.created_at')}:
                                    </Typography>
                                </Box>
                                <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                    <LocalCreatedAt source="created_at" />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(2) }}>
                                <IconMessageDots size="1rem" style={{ marginRight: 8 }} />
                                <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                    {translate('resources.conversations.fields.topic')}:
                                </Typography>
                            </Box>
                            <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                <Typography>{record.topic || translate('resources.conversations.unknown')}</Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(2) }}>
                                <IconFlag size="1rem" style={{ marginRight: 8 }} />
                                <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                    {translate('resources.conversations.fields.outcome')}
                                </Typography>
                            </Box>
                            <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                <Box sx={{ display: 'flex', gap: theme.spacing(2), flexWrap: 'wrap' }}>
                                    <FunctionField
                                        render={(record: ConversationRecord) => {
                                            const config = outcomeConfig[record.outcome];
                                            const Icon = config?.icon || IconInfoCircle;
                                            return (
                                                <CustomChip
                                                    label={translate(`resources.conversations.outcomes.${record.outcome}`)}
                                                    variant="outlined"
                                                    color="default"
                                                    icon={<Icon size={16} stroke={1.5} color={config?.color} />}
                                                />
                                            );
                                        }}
                                    />
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <IconTag size="1rem" style={{ marginRight: 8 }} />
                                <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                    {translate('resources.conversations.fields.tags')}:
                                </Typography>
                            </Box>
                            <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1}}>
                                    {Array.isArray(record.tags) && record.tags.map((tag: string) => (
                                        <CustomChip
                                            key={tag}
                                            label={tag}
                                            variant="filled"
                                        />
                                    ))}
                                </Box>
                                <TagsManager record={record} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: theme.spacing(2) }}>
                                <IconClock size="1rem" style={{ marginRight: 8 }} />
                                <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                                    {translate('resources.conversations.fields.duration')}:
                                </Typography>
                            </Box>
                            <Box sx={{ ml: 3, mb: theme.spacing(4) }}>
                                <DurationField record={record} source="duration" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Box>
    );
}; 