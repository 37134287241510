import React from 'react';
import { Card, CardHeader, CardContent, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ResponsiveContainer, ComposedChart, Area, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { useTranslate, useLocaleState } from 'react-admin';
import { format, parseISO } from 'date-fns';
import { Locale } from 'date-fns';
import { nl, enUS, es, fr, de, it } from 'date-fns/locale';
import { useTheme } from '@mui/material';


const ConversationStatsChart = ({ data }: Props) => {
    const translate = useTranslate();
    const theme = useTheme();
    const [chartType, setChartType] = React.useState<'duration' | 'count'>('duration');
    const [dateLocale] = React.useState<Locale>();

    if (!data) return null;

    // Format duration in HH:mm if > 60 minutes, otherwise in minutes
    const formatDuration = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        
        if (hours > 0) {
            return `${hours}:${minutes.toString().padStart(2, '0')}`;
        } else {
            return `${minutes}m`;
        }
    };

    const handleChartTypeChange = (
        event: React.MouseEvent<HTMLElement>,
        newChartType: 'duration' | 'count'
    ) => {
        if (newChartType !== null) {
            setChartType(newChartType);
        }
    };

    // Transform data to separate transferred and non-transferred calls
    const transformedData = data.map(item => ({
        ...item,
        // Subtract transferred_count from total call_count to get non-transferred calls
        direct_calls: item.call_count - item.transferred_count,
        transferred_calls: item.transferred_count
    }));

    // Add text style configuration
    const textStyle = {
        fontFamily: 'Figtree'
    };

    const getLocale = (localeString: string) => {
        const baseLocale = localeString.split('_')[0];
        const locales: { [key: string]: Locale } = { nl, es, fr, de, it };
        return locales[baseLocale] || enUS;
    };

    return (
        <Card sx={{ 
            borderRadius: 2,
            boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
        }}>
            <CardHeader 
                sx={{ 
                    p: 3,
                    '& .MuiCardHeader-title': { 
                        fontSize: '1.25rem',
                        fontWeight: 600,
                        color: theme.palette.text.primary
                    }
                }}
                title={translate(
                    chartType === 'duration' 
                        ? 'resources.conversations.stats.duration_title' 
                        : 'resources.conversations.stats.count_title'
                )}
                action={
                    <ToggleButtonGroup
                        value={chartType}
                        exclusive
                        onChange={handleChartTypeChange}
                        size="small"
                        sx={{
                            '& .MuiToggleButton-root': {
                                border: `1px solid ${theme.palette.primary.main}30`,
                                '&.Mui-selected': {
                                    backgroundColor: `${theme.palette.primary.main}15`,
                                    color: theme.palette.primary.main,
                                    '&:hover': {
                                        backgroundColor: `${theme.palette.primary.main}25`,
                                    }
                                }
                            }
                        }}
                    >
                        <ToggleButton value="duration">{translate('resources.conversations.stats.duration')}</ToggleButton>
                        <ToggleButton value="count">{translate('resources.conversations.stats.count')}</ToggleButton>
                    </ToggleButtonGroup>
                }
            />
            <CardContent sx={{ p: 3 }}>
                <div style={{ width: '100%', height: 350 }}>
                    <ResponsiveContainer>
                        <ComposedChart data={transformedData}>
                            <XAxis 
                                dataKey="date"
                                tickFormatter={(date) => format(parseISO(date), 'd MMM', { 
                                    locale: dateLocale 
                                })}
                                tick={{ ...textStyle }}
                            />
                            <YAxis 
                                label={{ 
                                    value: translate(
                                        chartType === 'duration' 
                                            ? 'resources.conversations.stats.duration_label' 
                                            : 'resources.conversations.stats.count_label'
                                    ), 
                                    angle: -90, 
                                    position: 'insideLeft',
                                    style: textStyle
                                }}
                                tickFormatter={(value) => chartType === 'duration' ? formatDuration(value) : value}
                                tick={{ ...textStyle }}
                            />
                            <Tooltip 
                                labelFormatter={(date) => format(parseISO(date as string), 'dd MMMM yyyy', { locale: dateLocale })}
                                formatter={(value: any, name: string) => {
                                    if (chartType === 'duration') {
                                        switch(name) {
                                            case translate('resources.conversations.stats.call_duration'):
                                                return [formatDuration(value), translate('resources.conversations.stats.call_duration')];
                                            case translate('resources.conversations.stats.transfer_duration'):
                                                return [formatDuration(value), translate('resources.conversations.stats.transfer_duration')];
                                            default:
                                                return [value, name];
                                        }
                                    }
                                    switch(name) {
                                        case translate('resources.conversations.stats.direct_calls'):
                                            return [value, translate('resources.conversations.stats.direct_calls')];
                                        case translate('resources.conversations.stats.transferred_calls'):
                                            return [value, translate('resources.conversations.stats.transferred_calls')];
                                        default:
                                            return [value, name];
                                    }
                                }}
                            />
                            <Legend 
                                wrapperStyle={textStyle}
                            />
                            {chartType === 'duration' ? (
                                <>
                                    <Area
                                        type="monotone"
                                        dataKey="call_duration"
                                        stroke="#368F8B"
                                        fill="#368F8B"
                                        fillOpacity={0.6}
                                        stackId="1"
                                        name={translate('resources.conversations.stats.call_duration')}
                                    />
                                    <Area
                                        type="monotone"
                                        dataKey="transfer_duration"
                                        stroke="#368F8B"
                                        fill="#368F8B"
                                        fillOpacity={0.2}
                                        stackId="1"
                                        name={translate('resources.conversations.stats.transfer_duration')}
                                    />

                                </>
                            ) : (
                                <>
                                    <Bar
                                        dataKey="direct_calls"
                                        stackId="a"
                                        fill="#368F8B"
                                        fillOpacity={0.7}
                                        name={translate('resources.conversations.stats.direct_calls')}
                                    />
                                    <Bar
                                        dataKey="transferred_calls"
                                        stackId="a"
                                        fill="#FC8077"
                                        fillOpacity={0.7}
                                        name={translate('resources.conversations.stats.transferred_calls')}
                                    />
                                </>
                            )}
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};

export default ConversationStatsChart;