import * as React from 'react';
import { Loading, useGetManyAggregate, useRecordContext, usePermissions, FunctionField, useTranslate, SelectInput, ReferenceArrayInput, ListButton, DateField, List, SimpleList, ReferenceField, TextField, Show, TextInput, SelectArrayInput, AutocompleteArrayInput, DateInput, SearchInput, TopToolbar, useGetIdentity, useShowController } from 'react-admin';
import { Box, Paper, Tabs, Tab } from '@mui/material';
import DurationField from './DurationField';
import { useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate, Route, Routes } from 'react-router-dom';
import { IconCheck, IconInfoCircle, IconBuildingStore, IconPhone, IconArrowsTransferUp, IconX, IconClipboardList, IconMessageDots, IconNote, IconSettings } from '@tabler/icons-react';
import CustomPagination from './components/react-admin/CustomPagination';
import { CustomFilterButton } from './components/react-admin/CustomFilterButton';
import CustomChip from './components/react-admin/CustomChip';
import { useTheme } from '@mui/material/styles';
import { Drawer } from '@mui/material';
import { RatingBadge } from './components/RatingBadge';
import TagsManager, { allTags } from './TagsManager';
import { Datagrid } from 'react-admin';
import { ConversationOverview } from './components/ConversationDetails';
import ConversationTranscript from './components/ConversationTranscript';
import AudioPlayer from './components/AudioPlayer';
import ConversationNotes from './components/ConversationNotes';


const tags = Object.keys(allTags).map(key => ({ id: key, name: key }));

export const TagsManagerWrapper = ({ isEditable = false, label }: { isEditable?: boolean, label?: string }) => {
    const record = useRecordContext();
    const translate = useTranslate();

    if (!record?.tags) return null;
    console.log('record', record);
    if (typeof record.tags === 'string') {
        record.tags = [record.tags];
    }

    return (
        <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
            {record.tags.map((tag: string) => (
                <CustomChip
                    key={tag}
                    label={translate(`resources.conversations.tags.${tag}`)}
                    variant="filled"
                    color="primary"
                    size="small"
                />
            ))}
        </div>
    );
};

export const LocalCreatedAt = (props: any) => {
    const record = useRecordContext();
    const { data: assistants, isLoading, error } = useGetManyAggregate('assistants', { ids: [record?.assistant_id] });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
            <DateField
            source={props.source}
            options={{ year: 'numeric', month: 'numeric', day: 'numeric',hour: '2-digit', minute: '2-digit', timeZone: assistants?.[0]?.timezone }}
            transform={(value: string) => new Date(value+'+00:00')}        
            />
        )
};



const filterToQuery = (searchText: any) => ({ name: `%${searchText}%` });

const conversationFilters = [
    <SearchInput source="search" alwaysOn />,
    <ReferenceArrayInput source="assistant_id" reference="assistants">
        <AutocompleteArrayInput optionText="name" style={{'minWidth':'300px'}} filterToQuery={filterToQuery} />
    </ReferenceArrayInput>,
    <ReferenceArrayInput source="assistant.customer_id" reference="customers" label="Customer">
        <AutocompleteArrayInput optionText="name" style={{'minWidth':'300px'}} filterToQuery={filterToQuery} label="Customer" />
    </ReferenceArrayInput>,
    <TextInput source="topic" />,
    <TextInput source="summary" />,
    <TextInput source="from_id" />,
    <SelectArrayInput source="tags" choices={tags} variant='outlined' />,
    <SelectInput
        source="outcome"
        emptyText="resources.conversations.outcomes.all"
        choices={[
            { id: 'completed', name: 'resources.conversations.outcomes.completed' },
            { id: 'caller_follow_up', name: 'resources.conversations.outcomes.caller_follow_up' },
            { id: 'business_follow_up', name: 'resources.conversations.outcomes.business_follow_up' },
            { id: 'transferred', name: 'resources.conversations.outcomes.transferred' },
            { id: 'information_provided', name: 'resources.conversations.outcomes.information_provided' },
            { id: 'no_action', name: 'resources.conversations.outcomes.no_action' },
        ]}
        alwaysOn
    />,
    <DateInput source="date_from" label="Date From" />,
    <DateInput source="date_to" label="Date To" />
];

const Assistant = () => {
    const record = useRecordContext();
    const { data: assistants, isLoading, error } = useGetManyAggregate('assistants', { ids: [record?.assistant_id] });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <div>{assistants?.[0]?.name}</div>;
};

const ListActions = () => (
    <TopToolbar>
        <CustomFilterButton />
    </TopToolbar>
);

type OutcomeType = 'no_action' | 'information_provided' | 'business_follow_up' | 'completed' | 'caller_follow_up' | 'transferred';

interface RecordType {
    outcome: OutcomeType;
}

const outcomeConfig = {
    'no_action': {
        color: '#d32f2f',
        icon: IconX,
        size: 16
    },
    'information_provided': {
        color: '#0288d1',
        icon: IconInfoCircle,
        size: 16
    },
    'business_follow_up': {
        color: '#ed6c02',
        icon: IconBuildingStore,
        size: 16
    },
    'completed': {
        color: '#2e7d32',
        icon: IconCheck,
        size: 16
    },
    'caller_follow_up': { 
        color: '#1976d2',
        icon: IconPhone,
        size: 16
    },
    'transferred': { 
        color: '#757575',
        icon: IconArrowsTransferUp,
        size: 16
    }
} as const;

const OutcomeField = ({ record }: { record: RecordType }) => {
    if (!record) return null;
    const translate = useTranslate();
    const config = outcomeConfig[record.outcome] || outcomeConfig['no_action'];
    const Icon = config.icon;

    return (
        <CustomChip 
            label={translate(`resources.conversations.outcomes.${record.outcome}`)}
            variant="outlined"
            color={'default'}
            icon={<Icon size={config.size} stroke={1.5} color={config.color}/>}
        />
    );
};

export const ConversationList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const theme = useTheme();

    return (
        <>
            <List
                exporter={false} 
                sort={{ field: 'created_at', order: 'DESC' }} 
                filters={conversationFilters} 
                actions={<ListActions />}
                pagination={<CustomPagination />}
            >
                {isSmall ? (
                    <SimpleList
                        primaryText={<LocalCreatedAt source="created_at" label="resources.assistants.fields.created_at" />}
                        secondaryText={record => 
                            <>
                            <strong>{translate('resources.conversations.fields.outcome')}:</strong> {record.outcome ? translate('resources.conversations.outcomes.'+record.outcome) : <i>n/a</i>}
                            <br />
                            <strong>{translate('resources.conversations.fields.topic')}:</strong> {record.topic ? record.topic : <i>n/a</i>}
                            <br />
                            {['admin', 'partner'].includes(permissions) && <TagsManagerWrapper label='Tags' isEditable={false} />}
                            <br />
                            <strong>{translate('resources.conversations.fields.summary')}:</strong> {record.summary ? record.summary : <i>n/a</i>}
                            </>
                        }
                        tertiaryText={record => 
                            <div style={{ textAlign: 'right', fontSize: '0.875rem' }}>
                            {record.from_id ? record.from_id : <i>n/a</i>}
                            {record.from_name ? ' - ' + record.from_name : ''}
                            <br />
                            <Assistant />
                            </div>
                        }
                        linkType={record => record.canEdit ? "edit" : "show"} 
                    />
                ) : (
                    <Datagrid rowClick="show" bulkActionButtons={false}
                    sx={{
                        '& .column-summary': {
                            display: 'table-cell',
                            maxWidth: '25em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',    
                        },
                        '& .column-phone_number': {
                            display: 'table-cell',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '8em',
                        },
                    }}
                    >
                        <LocalCreatedAt source="created_at" label="Creation time" />
                        <TextField source="from_id" sortable={false} label="Phone number" />
                        <DurationField source="duration" label="Duration" />
                        <TextField source="from_name" emptyText='Unknown' label="Name" />
                        <TextField source="topic" label="Topic" />
                        <FunctionField
                            source="outcome"
                            label="Outcome"
                            render={(record: RecordType | undefined) => {
                                if (!record || !record.outcome) return null;
                                const config = outcomeConfig[record.outcome] || outcomeConfig['no_action'];
                                const Icon = config.icon;

                                return (
                                    <CustomChip 
                                        label={translate(`resources.conversations.outcomes.${record.outcome}`)}
                                        variant="outlined"
                                        color={'default'}
                                        icon={<Icon size={config.size} stroke={1.5} color={config.color}/>}
                                    />
                                );
                            }}
                        />
                        <TagsManagerWrapper label="Tags" />
                        <ReferenceField source="assistant_id" reference="assistants" label="Assistant">
                            <TextField source="name" />
                        </ReferenceField>
                        {permissions === 'admin' && 
                            <FunctionField 
                                label="Rating" 
                                render={(record: any) => {
                                    const rating = JSON.parse(record.meta).rating;
                                    return rating ? <RatingBadge value={rating} /> : null;
                                }} 
                            />
                        }
                    </Datagrid>
                )}
            </List>
            <Routes>
                <Route 
                    path=":id" 
                    element={<ConversationShow />}
                />
            </Routes>
        </>
    );
};

export const ConversationShow = () => {
    const { record } = useShowController();
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const theme = useTheme();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(true);
    const [selectedTab, setSelectedTab] = React.useState('overview');

    const apiUrl = import.meta.env.VITE_API_URL;
    const auth = localStorage.getItem('auth');
    const parsedAuth = JSON.parse(auth || '{}');

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            navigate('/conversations', {
                replace: true,
                state: { _scrollToTop: false }
            });
        }, 300);
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case 'overview':
                return <ConversationOverview record={record}/>;
            case 'transcript':
                return <ConversationTranscript messages={record.messages} />;
            case 'notes':
                return <ConversationNotes record={record}/>;
            case 'admin':
                return record?.meta ? (
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.conversations.fields.rating')}
                        </Typography>
                        <RatingBadge value={JSON.parse(record.meta).rating} />
                    </Box>
                ) : null;
            default:
                return null;
        }
    };

    return (
        <>
            <ConversationList />
            <Drawer
                anchor="right"
                open={open}
                onClose={handleClose}
                SlideProps={{ 
                    appear: true,
                    direction: "left",
                    timeout: 300
                }}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: '700px',
                        padding: theme.spacing(3),
                        backgroundColor: theme.palette.background.paper,
                    },
                }}
            >
                <IconX
                    style={{
                        position: 'absolute',
                        top: '16px',
                        right: '16px',
                        cursor: 'pointer',
                        zIndex: 1,
                    }}
                    onClick={handleClose}
                    size={24}
                />
                <Typography variant="h6" fontWeight="bold" fontSize="1.25rem">
                    Conversation Details
                </Typography>
                <Show>
                    <Box sx={{ boxShadow: theme.shadows[0], backgroundColor: theme.palette.background.paper }}>
                        <Box >
                            <AudioPlayer 
                                src={`${apiUrl}/conversations/${record?.id}/recording.mp3?access_token=${parsedAuth.access_token}`}
                            />
                        </Box>
                        <Paper sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            mb: 1,
                            width: '100%',
                            height: '100%',
                            color: theme.palette.text.primary,
                            bgcolor: 'white',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                        }}>
                            <Tabs
                                value={selectedTab} 
                                onChange={(_, newValue) => setSelectedTab(newValue)}
                                variant="fullWidth"
                                sx={{
                                    '& .MuiTab-root': {
                                        minWidth: 0,
                                        flex: 1,
                                        textTransform: 'none',
                                    }
                                }}
                            >
                                <Tab
                                    icon={<IconClipboardList size="1rem" />}
                                    label="Overview"
                                    value="overview"
                                    iconPosition="start"
                                />
                                <Tab
                                    icon={<IconMessageDots size="1rem" />}
                                    label="Transcript"
                                    value="transcript"
                                    iconPosition="start"
                                />
                                <Tab
                                    icon={<IconNote size="1rem" />}
                                    label="Notes"
                                    value="notes"
                                    iconPosition="start"
                                />
                                {record?.meta && (
                                    <Tab
                                        icon={<IconSettings size="1rem" />}
                                        label="Admin"
                                        value="admin"
                                        iconPosition="start"
                                    />
                                )}
                            </Tabs>
                        </Paper>
                        {renderTabContent()}
                    </Box>
                </Show>
            </Drawer>
        </>
    );
};