import { styled } from '@mui/material/styles';
import { Chip, ChipProps } from '@mui/material';
import { PaletteColor } from '@mui/material/styles';

type ColorOptions = 'primary' | 'secondary' | 'default' | 'success' | 'error' | 'warning' | 'info';

interface CustomChipProps extends Omit<ChipProps, 'variant'> {
  variant?: 'outlined' | 'filled';
  color?: ColorOptions;
  size?: 'small' | 'medium';
}

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'size',
})<CustomChipProps>(({ theme, variant, color = 'default', size = 'medium' }) => {
  const mainColor = (theme.palette[color as keyof typeof theme.palette] as PaletteColor)?.main || theme.palette.grey[300];
  
  return {
    borderRadius: theme.shape.borderRadius,
    '&.MuiChip-root': {
      fontSize: size === 'small' ? '0.75rem !important' : theme.typography.body2.fontSize,
      padding: size === 'small' ? `${theme.spacing(1)} !important` : `${theme.spacing(1)} !important`,
      height: size === 'small' ? '20px !important' : undefined,
      minHeight: size === 'small' ? '20px !important' : undefined,
    },
    color: mainColor,

    ...(variant === 'outlined' && {
      backgroundColor: 'transparent',
      border: '1px solid',
      borderColor: mainColor,
      color: 'black !important'
    }),

    ...(variant === 'filled' && {
      backgroundColor: `${mainColor}40`,
      color: mainColor,
      border: `1.5px solid ${mainColor}45`,
      fontWeight: 'bold'
    }),

    '&.MuiChip-root .MuiChip-label': {
      padding: size === 'small' ? '0 4px !important' : `${theme.spacing(1)} !important`,
      fontSize: 'inherit !important',
      lineHeight: size === 'small' ? '20px !important' : 'inherit',
    },

    '& .MuiChip-deleteIcon': {
      fontSize: '10px',
      margin: '0 4px',
      color: 'inherit',
      height: size === 'small' ? '16px !important' : 'auto',
      width: size === 'small' ? '16px !important' : 'auto',
    },
  };
});

const CustomChip = ({ label, variant = 'filled', ...props }: CustomChipProps) => (
  <StyledChip
    label={label}
    variant={variant}
    {...props}
  />
);

export default CustomChip;
